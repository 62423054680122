/*MAIN OVERRIDES*/
html {
  height: 100%;
}

.full-display-element {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  background-color: black;
  display: flex;
  justify-content: center;/*define the horizontally centering*/
  align-items: center;/*define the vertically centering*/
  pointer-events: all;
}

.transition-display-none {
  opacity: 0;
  transition: opacity 1s linear;
  -webkit-transition: opacity 1s linear;
  -moz-transition: opacity 1s linear;
  -o-transition: opacity 1s linear;
  -ms-transition: opacity 1s linear;
}

.overlayFullCameraStatus {
  background-color: black;
  position: fixed;
  bottom:45px;
  right:35px;
}

.sixteen-by-nine {
  object-fit: cover;
  aspect-ratio: 16/9;
}

.four-by-three {
  object-fit: cover;
  aspect-ratio: 4/3;
}

.one-by-one {
  object-fit: cover;
  aspect-ratio: 1/1;
}

.bg-colour-1 {
  background-color: #375a7f;
  border-color: #375a7f;
}
.bg-colour-1:hover {
  background-color: #28415b;
  border-color: #243a53;
}
.bg-colour-1:disabled {
  background-color: #28415b;
  border-color: #243a53;
}
.bg-colour-2 {
  background-color: #444;
  border-color: #444;
}
.bg-colour-2:hover {
  background-color: #313131;
  border-color: #2b2a2a;
}
.bg-colour-2:disabled {
  background-color: #313131;
  border-color: #2b2a2a;
}
.bg-colour-3 {
  background-color: #f39c12;
  border-color: #f39c12;
}
.bg-colour-3:hover {
  background-color: #d4860b;
  border-color: #c87f0a;
}
.bg-colour-3:disabled {
  background-color: #d4860b;
  border-color: #c87f0a;
}
.bg-colour-4 {
  background-color: #00bc8c;
  border-color: #00bc8c;
}
.bg-colour-4:hover {
  background-color: #008966;
  border-color: #007c5d;
}
.bg-colour-4:disabled {
  background-color: #008966;
  border-color: #007c5d;
}
textarea{
  font-family: monospace;
}

.highlight-glow {
  border-color: #FF0000;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
}

.swiper-container {
  width: 100%;
  height: 500px;
}


.restricScreenSize {
  margin: 0 auto;
  max-width: 2000px;
}
.heatMapRight {
  width:19px;
  height:40px;
  margin-right:1px;
  margin-top:1px;
  margin-bottom:1px;
  border: 1px solid black;
}
.contentEditable{
  border: 1px inset grey;
}
.bg-dark-success{
  background-color:#0F2400;
}
.bg-dark-success:hover{
  background-color:#183B00;
}
.bg-dark-failed{
  background-color:#240005;
}
.bg-dark-failed:hover{
  background-color:#47000A;
}
.historyImage{
  width:75%;
}
.heatMapLeft {
  width:19px;
  height:40px;
  margin-left:1px;
  margin-bottom:1px;
  margin-top:1px;
  border: 1px solid black;
}
.heatMap {
  width:40px;
  height:40px;
  margin-left:1px;
  margin-bottom:1px;
  margin-top:1px;
  border: 1px solid black;
}
body {
  background-color: black;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("/images/bg.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  color: grey;
  height:100%;
}
.flex-stretch-octofarm {
  display: flex;
  align-items: stretch;
}
button > * {
  pointer-events: none;
}

hr {
  border-color: inherit;
  margin-top: 4px;
  margin-bottom: 4px;
}
li {
  padding: 3px !important;
}
.row {
  margin-left: 0px;
  margin-right: 0px;
}

.largeWidth {
  margin: 0 auto;
  max-width: 700px;
}

.dropdown-menu {
  z-index: 5000;
}

/* Colours */
.textIdle {
  color: #262626;
}
.textActive {
  color: #f39c12;
}

.textOffline {
  color: #e74c3c;
}

.textClosed {
  color: #e74c3c;
}

.textComplete {
  color: #00bc8c;
}

.textInfo {
  color: #3498db;
}

.text-offline {
  color: #401418;
}

.Info {
  background-color: #25386e;
}

.Idle {
  background-color: #262626;
}
.Active {
  background-color: #583c0e;
}
.Offline {
  background-color: #2e0905;
}
.Disconnected {
  background-color: #2e0905;
}
.Complete {
  background-color: #00330e;
}

.tempCool {
  background-color: cyan;
}
.tempCooling {
  background-color: darkBlue;
}
.tempActive {
  background-color: orange;
}
.tempSuccess {
  background-color: green;
}
.tempOffline {
  background-color: #e74c3c;
}
.bg-offline {
  background-color: #401418;
}

.MultiSelected {
  background-color: black;
  color: green;
}

.toolOn {
  color: green;
}
.toolOut {
  color: orange;
}
.toolUnder {
  color: cyan;
}

/*Scroll Bar*/
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #303030;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* Disable a links */
a.notyet {
  pointer-events: none;
  color: grey !important;
}
/*Progres Bar*/
.progress {
  height: 15px;
}
/* CUSTOM CLASSES */
/* Dashboard */
[class*="col-"] {
  padding: 4px;
}
.card {
  margin: 5px;
}
.overflow-x {
  overflow-x: scroll;
  overflow-y: hidden;
}
.dashHeader {
  padding: 5px;
}

.dashBody {
  padding: 5px;
  overflow-y: auto;
}

.dashBody p {
  margin-bottom: 0px !important;
}

.dashFooter {
  padding: 5px;
}

.fileHeader {
  padding: 5px;
}

.fileBody {
  padding: 5px;
  overflow-y: auto;
  min-height: 60vh;
  height: 60vh;
  max-height: 60vh;
}
.fileBody p {
  margin-bottom: 0px !important;
}
.printerSelected {
  border-left: #f39c12 4px solid;
}
.printerDisabled {
  border-left: #e74c3c 4px solid;
}
.printerEnabled {
  border-left: green 4px solid;
  background-color: transparent;
  opacity: 1.0;
}
.fileFooter {
  padding: 5px;
}

.search-control:focus {
  border-width: 0;
  border-bottom-width: 1px;
  border-color: rgb(36, 0, 104);
  border-radius: 0;
  padding-left: 0;
  color: #999;
  background-color: #444;
  outline-width: 0;
}
.search-control-underlined {
  border-width: 0;
  border-bottom-width: 1px;
  border-color: black;
  border-radius: 0;
  padding-left: 0;
  color: #fff;
  background-color: #444;
}
/* json printer import */
#uploadFileModalContentForm{
  display:inline-block;
  margin-bottom: 0px;
}
.fileElem {
  display: none;
  padding-left: 5px;
  padding-right: 5px;
}
/*Hide the choose file*/
#gcodeUpload {
  display: none;
  padding-left: 5px;
  padding-right: 5px;
}
.noBlue {
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none;
}
.highlight {
  box-shadow: 0px 0px 20px 5px rgba(0,0,0,0.2);
  filter: brightness(20%)
}
/*Camera View*/
.cameraContain {
  position: relative;
  padding: 5px;
  width: auto;
}
.cameraContain:-webkit-full-screen,
.cameraContain:-moz-full-screen,
.cameraContain:-ms-fullscreen,
.cameraContain:fullscreen {
  width: auto !important;
  object-fit: contain !important;
}
/*.camButtons:-webkit-full-screen,*/
/*.camButtons:-moz-full-screen,*/
/*.camButtons:-ms-fullscreen,*/
/*.camButtons:fullscreen {*/
/*  width: auto;*/
/*  height: 90vh;*/
/*}*/

.camButtons{
  position: absolute;
  bottom: 15px;
  z-index: 1000;
}
.camName {
  pointer-events: none;
  background-color:black;
  position: absolute;
  z-index: 10;
  width: 100%;
  top:1px;
  left: -1px;
  opacity: 0.7;
}
.camExtra {
  pointer-events: none;
  display: none;
  background-color:black;
  position: absolute;
  z-index: 10;
  width: 100%;
  top:23px;
  left: -1px;
  opacity: 0.7;
}
.camImg {
  pointer-events: none;
  max-height: 100%;
}
.camTemps {
  display: none;
  pointer-events: none;
  background-color:black;
  position: absolute;
  z-index: 998;
  width: 100%;
  bottom: 16px;
  left: -1px;
  opacity: 0.7;
}
.cameraContain:hover .camExtra {
  display: block;
  transition: opacity 0.3s;
}
.cameraContain:hover .camTemps {
  display: block;
  transition: opacity 0.3s;
}

.camProgress {
  pointer-events: none;
  background-color:black;
  position: absolute;
  z-index: 998;
  width: 100%;
  height: 15px;
  bottom: 1px;
  left: -1px;
  opacity: 0.8;
}
.rotate180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
/* PRINTER MAP TILES */
.printer-map-tile:hover {
  border: 1px dashed lime;
  /*opacity: 0.8;*/
  /*border: 2px solid red;*/
  /*margin: -2px;*/
}
.printer-map-tile-selected {
  border: 1px solid lime !important;
  margin: -5px;
}
.printer-map-tile-stub {
  /*background: rgba(0, 0, 0, .3);*/
  opacity: 0.3;
}
.printer-map-idle {
  background-color: #262626;
}
/*PRINTER MANAGER*/
.terminal-window {
  padding: 5px;
  width: 100%;
  height: 440px;
  max-height: 440px;
  overflow: auto;
  font-family: monospace;
}
/*SERVER WORK OVERLAY*/
#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 99999; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
#overlayIcon {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 0);
  z-index: 1023;
}
.noStorage {
  position: relative;
  top: 50%;
  transform: translate(0%, 0);
  width: 100%;
  z-index: 1023;
}

/*Terminal*/
.temperatureMessage {
  color:#f39c12;
}
.sdMessage {
  color:#3498db;
}
.waitMessage {
  color:#e74c3c;
}
label {
  cursor: pointer;
  /* Style as you please, it will become the visible UI component. */
}
.hidden {
  display: none !important;
}
#fileUploadBtn {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
#fileUploadPrintBtn {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
#multiFileUploadBtn {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.modal-dialog {
  margin-bottom: 0 !important;
}

.modal-body {
  max-height: 80vh;
  overflow-y: auto;
  overflow-wrap: normal;
}

.sortableList {
  cursor: move;
}
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xl-5 {
  display: block;
  position: relative !important;
  min-height: 1px !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  width: 20% !important;
  float: left !important;
}
@media (min-width: 768px) {
  .modal-xl {
    max-width: 95% !important;
  }
}
@media (min-width: 992px) {
  .modal-xl {
    max-width: 90% !important;
  }
  .sticky-table {
    min-width:100px;
    position: sticky;
    top: 0px;
    z-index: 1000;
  }

}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 85% !important;
  }
  .sticky-table {
    min-width:100px;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
}

.carousel-item {
  height: 100vh;
  min-height: 350px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.carousel-caption {
  top: 0 !important;
  bottom: auto !important;
  display: block !important;
}
.carousel-caption:hover {
  display: block !important;
}


.stuck {
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
}

.userpic {
  height: 75px;
  width: 75px;
  clear: both;
  margin: 0 auto;
  display: block;
  overflow: hidden;
  border-radius: 100%;
  box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
  position: relative;
}
.userimg {
  height: auto;
  width: 100%;
  border-radius: 100%;
}
